body{
  background:linear-gradient(to right, #feac5e, #c779d0, #4bc0c8);
  margin:0;
  padding: 0;
}

.container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px auto;
  width: 95%;
  max-width: 1800px;
}
.containerItem{
  border-radius: 7px;
  width: 500px;
  margin: 20px;
  text-align: center;
  padding: 10px 10px;
}
.containerItem:hover{
  background:linear-gradient(to right,#fdfc47, #24fe41);
}
.productName{
  color:white;
  margin-top:15px;
  font-weight: bold;
  font-size: 24px
}
.backToGoodsListBtn{
  background-color: #1c3455;
  font-weight: bold;
  border-radius: 6px;
  margin: 50px auto;
  cursor: pointer;
  padding: 13px 40px;
  text-align: center;
  width: 300px;
  text-align: center;
  color: #FFF;
  text-decoration: none;
}
.addToCart{
  display: block;
  width: 100%;
  margin: 25px auto;
  font-size: 20px;
  font-weight: bold;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none;
  -moz-user-select: none;
  -o-user-select: none;
}

.addToCartBtn{
  padding: 15px 20px;
  border-radius: 6px;
  background:linear-gradient(to right,#00c9ff, #92fe9d);
  color:#1c3455;
  cursor: pointer;
}

.subtractBtn{
  background:linear-gradient(to right, #AAFFA9, #11FFBD);
  font-weight: bold;
  color:#1c3455;
  border-radius: 6px;
  padding: 5px 10px;
  margin-right: 7px;
  margin-left: 7px;
  cursor: pointer;
}
.addBtn{
  background:linear-gradient(to right, #AAFFA9, #11FFBD);
  font-weight: bold;
  color:#1c3455;
  border-radius: 6px;
  padding: 5px 10px;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
}

.addToCartInTotal{
  color:#FFF;
}

.ProductDetail{
  margin: 10px auto;
  width: 1000px;
  font-size: 20px;
  font-weight: bold;
}

.ProductDetail img{
  width:450px;
}

.ProductDetail td{
  padding : 20px;
}

.nothingInCart{
  color: #fff;
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
}

.cartSection{
  width: 70%;
}

.productSubTotal{
  font-size: 30px;
  font-weight: bold;
  margin-right: 30px;
}

.checkoutTable{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
}
.checkoutTable tr{
  margin: 10px 10px;
}
.checkoutTable td{
  padding:0px;
  font-size: 16px;
}
.checkoutTable img{
  width: 150px;
  margin: 15px;
}

.checkoutSection{
  width: 30%;
  font-size: 20px;
  text-align: center;
}
.checkoutSection div{
  margin: 20px 12px;
}
.checkoutSection button{
  font-size: 22px;
  background-color: #0d3164;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  padding: 13px 40px;
  text-align: center;
  text-align: center;
  color: #FFF;
  text-decoration: none;
}
.grandTotal{
  font-size: 42px;
  font-weight: bold;
}

.freeShipping{
  font-weight: bold;
  color:springgreen;
}
.noShipping{
  color:#0d3164;
  font-weight: bold;
}

.fixPos{
  position:fixed;
  width: 30%;
}

nav{
  position: fixed;
  text-align: center;
  top: 0;
  padding: 30px 0px;
  width: 100%;
  background-color: #136a8a;
}
nav a{
  font-size: 20px;
  text-decoration: none;
  margin: 10px 10px;
  padding: 13px 40px;
  background-color: #fdf9de;
  border-radius: 8px;
  font-weight: bold;
  color: black;
}
nav a:visited{
  color: black;
}

h1{
  text-align: center;
  margin-top:120px;
  color:#FFF;
}
img{
  width: 90%;
  height:auto;
  border-radius: 20px;
}
